import { currentDate_deprecated } from '../../helpers';
import { IPenHistoryInitialState } from '../../utils/Interface/PenHistoryInterface';

export const PEN_HISTORY_INITIAL_STATE: IPenHistoryInitialState = {
    pens: [{ name: 'Select Pen', id: 0 }],
    selectedPen: 0,
    penHistory: [
        {
            baseAuditId: 0,
            createdDateTime: '',
            createdBy: '',
            type: '',
            action: '',
            auditLog: '',
            penId: 0,
            lotId: 0,
            auditType: '',
            operation: '',
            date: '',
            time: '',
            startRationName: '',
            endRationName: '',
            totalCall: 0,
            feedCallId: 0,
            rationName: '',
            deliveredAmount: 0,
            scheduledAmount: 0,
            feedingNumber: 0,
            createdSource: '',
            lotDetails: [],
        },
    ],
    isPenDropDownDisabled: false,
    penHistoryDate: currentDate_deprecated('YYYY-MM-DD'),
};
