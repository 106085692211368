import { useEffect } from 'react';
import FileSaver from 'file-saver';
import { utils, WorkBook, write } from 'xlsx';
import { Button } from 'react-bootstrap';

import {
    IExportCSV,
    IWorkSheetCols,
} from '../../utils/Interface/CommonInterface';
import Constants from '../../utils/Constants';
import FullPageLoader from './FullPageLoader';
import { useLoading } from '../../commonHooks/useLoading';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import ProtectedSpinnerButton from './ProtectedSpinnerButton';

const { isCA12645On_ProtectedReports } = feedlotManagerFeatures;

const ExportXLSX = ({
    csvData,
    fileName,
    heading,
    excelHeader,
    onClick,
    buttonLabel = Constants.button.excelExport,
    showLoading = true,
    validateLoading = true,
    secondaryClass,
    disabled = false,
    showSpinner = false,
}: IExportCSV) => {
    const { loading, setLoading } = useLoading();

    const handleExport = () => {
        setLoading(true);
    };

    useEffect(() => {
        if (loading) {
            onClick(setLoading);
        }
    }, [loading]);

    useEffect(() => {
        const sheets = Object.keys(csvData);
        const wb: WorkBook = { Sheets: {}, SheetNames: [] };

        const fetchMaxWidth = (fieldName: string, sheetName: string) => {
            return Math.max(
                ...csvData[sheetName].map(processingProtocol => {
                    const value = processingProtocol[fieldName];
                    return (value || '').toString().length;
                }),
            );
        };

        const fetchColumnsMaxWidth = (sheetName: string) => {
            const csvDataKeys = Object.keys(csvData[sheetName][0]);

            return csvDataKeys.map(header => ({
                wch: fetchMaxWidth(header, sheetName),
            }));
        };

        const fetchWorkSheet = (
            wsCols: IWorkSheetCols[],
            sheetName: string,
        ) => {
            if (heading && excelHeader) {
                const ws = utils.json_to_sheet(excelHeader, {
                    header: heading,
                    skipHeader: true,
                });

                ws['!cols'] = wsCols;

                utils.sheet_add_json(ws, csvData[sheetName], {
                    header: heading,
                    skipHeader: true,
                    origin: -1,
                });

                return ws;
            } else {
                return utils.json_to_sheet(csvData[sheetName], {
                    skipHeader: true,
                });
            }
        };

        const exportXlsxFile = () => {
            const excelBuffer = write(wb, {
                bookType: 'xlsx',
                type: 'array',
            });

            const data = new Blob([excelBuffer], {
                type: Constants.exportCSVConstant.fileType,
            });

            FileSaver.saveAs(
                data,
                fileName + Constants.exportCSVConstant.fileExtension,
            );
        };

        if (sheets.length && (loading || !validateLoading)) {
            sheets.forEach((sheetName: string) => {
                if (
                    csvData[sheetName].length &&
                    (loading || !validateLoading)
                ) {
                    const wsCols = fetchColumnsMaxWidth(sheetName);
                    const ws = fetchWorkSheet(wsCols, sheetName);

                    wb.Sheets[sheetName] = ws;
                    wb.SheetNames.push(sheetName);
                }
            });

            if (wb.SheetNames.length) {
                exportXlsxFile();
            }
            setLoading(false);
        }
    }, [csvData]);

    return (
        <>
            {showLoading && loading && !isCA12645On_ProtectedReports && (
                <FullPageLoader />
            )}
            {isCA12645On_ProtectedReports ? (
                <ProtectedSpinnerButton
                    className={`secondary ${secondaryClass}`}
                    disabled={disabled || loading}
                    onClick={handleExport}
                    showSpinner={showSpinner || loading}
                    buttonContent={buttonLabel}
                />
            ) : (
                <Button
                    className={`secondary ${secondaryClass}`}
                    disabled={disabled}
                    onClick={handleExport}
                >
                    {buttonLabel}
                </Button>
            )}
        </>
    );
};

export default ExportXLSX;
