import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { QUICKBOOKS_INITIAL_STATE } from '../Constants/quickBookInitialState';
import {
    IErrorInput,
    TabOption,
} from '../../utils/Interface/QuickBookInterface';

const quickBookSlice = createSlice({
    name: 'quickBook',
    initialState: { ...QUICKBOOKS_INITIAL_STATE },
    reducers: {
        setISQuickBookAuthenticated: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isQuickBookAuthenticated = action.payload;
        },
        setSelectedTabOption: (state, action: PayloadAction<TabOption>) => {
            state.selectedTabOption = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setUserEmail: (state, action: PayloadAction<string>) => {
            state.userEmail = action.payload;
        },
        setInputError: (state, action: PayloadAction<IErrorInput>) => {
            state.inputErrors = action.payload;
        },
        setUserQbRequestId: (state, action: PayloadAction<number>) => {
            state.userQbRequestID = action.payload;
        },
        setQbSettingId: (state, action: PayloadAction<number>) => {
            state.qbSettingId = action.payload;
        },
        setIsTenantQbConfigCreated: (state, action: PayloadAction<boolean>) => {
            state.isTenantQbConfigCreated = action.payload;
        },
    },
});

export const {
    setISQuickBookAuthenticated,
    setSelectedTabOption,
    setIsLoading,
    setUserEmail,
    setInputError,
    setUserQbRequestId,
    setQbSettingId,
    setIsTenantQbConfigCreated,
} = quickBookSlice.actions;
export const QuickBookSlice = quickBookSlice.reducer;
