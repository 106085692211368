export interface ITabOption {
    label: string;
    name: string;
    value: string;
}

export enum TabOption {
    Yes = 'yes',
    No = 'no',
}

export interface IQuickBookVerificationModalProps {
    showModal: boolean;
    closeModalHandler: () => void;
    closeModal: () => void;
}

export interface IErrorInput {
    userName: boolean;
}

export interface IQuickBookInitialState {
    isQuickBookAuthenticated: boolean;
    isTenantQbConfigCreated: boolean;
    selectedTabOption: TabOption;
    isLoading: boolean;
    userEmail: string;
    inputErrors: IErrorInput;
    userQbRequestID: number;
    qbSettingId: number;
}
