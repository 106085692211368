import { rootApi as api } from '../rootApi';
export const addTagTypes = ['QuickBooks'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: build => ({
            postApiFmQuickbooksUserByQbUserName: build.mutation<
                PostApiFmQuickbooksUserByQbUserNameApiResponse,
                PostApiFmQuickbooksUserByQbUserNameApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/quickbooks/user/${queryArg.qbUserName}`,
                    method: 'POST',
                }),
                invalidatesTags: ['QuickBooks'],
            }),
            getApiFmQuickbooksCfgfile: build.query<
                GetApiFmQuickbooksCfgfileApiResponse,
                GetApiFmQuickbooksCfgfileApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/quickbooks/cfgfile`,
                    params: {
                        fileType: queryArg.fileType,
                    },
                }),
                providesTags: ['QuickBooks'],
            }),
            getApiFmQuickbooksUserByUserQbRequestId: build.query<
                GetApiFmQuickbooksUserByUserQbRequestIdApiResponse,
                GetApiFmQuickbooksUserByUserQbRequestIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/quickbooks/user/${queryArg.userQbRequestId}`,
                }),
                providesTags: ['QuickBooks'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as baseQuickBooksApi };
export type PostApiFmQuickbooksUserByQbUserNameApiResponse = unknown;
export type PostApiFmQuickbooksUserByQbUserNameApiArg = {
    qbUserName: string;
};
export type GetApiFmQuickbooksCfgfileApiResponse = unknown;
export type GetApiFmQuickbooksCfgfileApiArg = {
    /** File Type or Name */
    fileType: FeedlotManagerCommonEnumsQbConnectionFileType;
};
export type GetApiFmQuickbooksUserByUserQbRequestIdApiResponse =
    /** status 200 Success */ TenantManagementDataEntitiesTenantConfig;
export type GetApiFmQuickbooksUserByUserQbRequestIdApiArg = {
    userQbRequestId: number;
};
export type FeedlotManagerCommonEnumsQbConnectionFileType =
    | 'Instructions'
    | 'InvoiceTemplate'
    | 'QWC';
export type TenantManagementCommonEnumsAddressType =
    | 'Home'
    | 'Business'
    | 'Billing'
    | 'Shipping';
export type TenantManagementCommonRoles =
    | 'None'
    | 'AppAdmin'
    | 'BackgroundService'
    | 'AnyUserRole'
    | 'AnyManageRole'
    | 'Owner'
    | 'SuperAdmin'
    | 'Admin'
    | 'Manager'
    | 'OfficeManager'
    | 'Finance'
    | 'WarehousingApp'
    | 'Buyer'
    | 'TruckingCompany'
    | 'AnyAccountRole'
    | 'AnyAccountManageRole'
    | 'AccountOwner'
    | 'AccountAdmin'
    | 'AccountManager'
    | 'AccountUser'
    | 'FeedlotManager'
    | 'InvoiceManager'
    | 'HealthManager'
    | 'Cowboy'
    | 'Operator'
    | 'FeedTruckDriver'
    | 'AccountStakeholder'
    | 'User';
export type TenantManagementDataEntitiesRole = {
    accountUsers?: TenantManagementDataEntitiesAccountUser[] | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: TenantManagementCommonRoles;
    roleId?: number;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesRoleRead = {
    accountUsers?: TenantManagementDataEntitiesAccountUser[] | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: TenantManagementCommonRoles;
    roleId?: number;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesAccountUser = {
    account?: TenantManagementDataEntitiesAccount;
    accountId?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    roles?: TenantManagementDataEntitiesRole[] | null;
    user?: TenantManagementDataEntitiesUser;
    userId?: number;
    userPrimary?: boolean | null;
};
export type TenantManagementDataEntitiesAccountUserRead = {
    account?: TenantManagementDataEntitiesAccount;
    accountId?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    roles?: TenantManagementDataEntitiesRoleRead[] | null;
    user?: TenantManagementDataEntitiesUser;
    userId?: number;
    userPrimary?: boolean | null;
};
export type TenantManagementCommonEnumsMfaType = 'Email' | 'SMS';
export type TenantManagementDataEntitiesDeviceType = 'Web' | 'Mobile';
export type TenantManagementDataEntitiesRefreshToken = {
    createdBy?: string | null;
    createdDatetime?: string;
    deviceType?: TenantManagementDataEntitiesDeviceType;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    refreshTokenId?: number;
    token?: string | null;
    tokenExpiry?: string | null;
    userId?: number;
};
export type TenantManagementDataEntitiesScope = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    scopeId?: number;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesScopeRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    scopeId?: number;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesUser = {
    accounts?: TenantManagementDataEntitiesAccountUser[] | null;
    addresses?: TenantManagementDataEntitiesAddress[] | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    enabled?: boolean | null;
    entityCache?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    latestLogin?: string | null;
    mfaType?: TenantManagementCommonEnumsMfaType;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    profileImageId?: string | null;
    refreshToken?: string | null;
    refreshTokenExpiry?: string | null;
    refreshTokens?: TenantManagementDataEntitiesRefreshToken[] | null;
    roles?: TenantManagementDataEntitiesRole[] | null;
    salt?: string | null;
    scopes?: TenantManagementDataEntitiesScope[] | null;
    tenant?: TenantManagementDataEntitiesTenant;
    tenantId?: string;
    timezoneInfo?: string | null;
    trackedDisabledAccounts?: TenantManagementDataEntitiesAccountUser[] | null;
    transientAuthExpiry?: string | null;
    transientAuthToken?: string | null;
    transientContext?: string | null;
    userhash?: string | null;
    userId?: number;
    username?: string | null;
    verified?: boolean | null;
};
export type TenantManagementDataEntitiesUserRead = {
    accounts?: TenantManagementDataEntitiesAccountUserRead[] | null;
    addresses?: TenantManagementDataEntitiesAddress[] | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    enabled?: boolean | null;
    entityCache?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    latestLogin?: string | null;
    mfaType?: TenantManagementCommonEnumsMfaType;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    profileImageId?: string | null;
    refreshToken?: string | null;
    refreshTokenExpiry?: string | null;
    refreshTokens?: TenantManagementDataEntitiesRefreshToken[] | null;
    roles?: TenantManagementDataEntitiesRoleRead[] | null;
    salt?: string | null;
    scopes?: TenantManagementDataEntitiesScopeRead[] | null;
    tenant?: TenantManagementDataEntitiesTenant;
    tenantId?: string;
    timezoneInfo?: string | null;
    trackedDisabledAccounts?:
        | TenantManagementDataEntitiesAccountUserRead[]
        | null;
    transientAuthExpiry?: string | null;
    transientAuthToken?: string | null;
    transientContext?: string | null;
    userhash?: string | null;
    userId?: number;
    username?: string | null;
    verified?: boolean | null;
};
export type TenantManagementDataEntitiesAddress = {
    accounts?: TenantManagementDataEntitiesAccount[] | null;
    addressId?: number;
    city?: string | null;
    country?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    state?: string | null;
    streetAddress?: string | null;
    streetAddress2?: string | null;
    type?: TenantManagementCommonEnumsAddressType;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesAddressRead = {
    accounts?: TenantManagementDataEntitiesAccount[] | null;
    addressId?: number;
    city?: string | null;
    country?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    state?: string | null;
    streetAddress?: string | null;
    streetAddress2?: string | null;
    type?: TenantManagementCommonEnumsAddressType;
    users?: TenantManagementDataEntitiesUserRead[] | null;
};
export type TenantManagementDataEntitiesAccount = {
    accountId?: number;
    addresses?: TenantManagementDataEntitiesAddress[] | null;
    attributes?: {
        [key: string]: any | null;
    } | null;
    contactEmail?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    enabled?: boolean | null;
    entityCache?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    profileImageId?: string | null;
    tenant?: TenantManagementDataEntitiesTenant;
    tenantId?: string;
    timezoneInfo?: string | null;
    users?: TenantManagementDataEntitiesAccountUser[] | null;
};
export type TenantManagementDataEntitiesAccountRead = {
    accountId?: number;
    addresses?: TenantManagementDataEntitiesAddressRead[] | null;
    attributes?: {
        [key: string]: any | null;
    } | null;
    contactEmail?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    enabled?: boolean | null;
    entityCache?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    profileImageId?: string | null;
    tenant?: TenantManagementDataEntitiesTenant;
    tenantId?: string;
    timezoneInfo?: string | null;
    users?: TenantManagementDataEntitiesAccountUserRead[] | null;
};
export type TenantManagementModelsUserManagementSubscription = {
    customerId?: string | null;
    productFamily?: string | null;
    subscriptionId?: string | null;
    subscriptionName?: string | null;
    subscriptionPlan?: string | null;
};
export type TenantManagementModelsUserManagementTenantContext = {
    subscriptions?: TenantManagementModelsUserManagementSubscription[] | null;
};
export type TenantManagementDataEntitiesTenant = {
    accounts?: TenantManagementDataEntitiesAccount[] | null;
    contactEmail?: string | null;
    contactName?: string | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    entityCache?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    profile?: TenantManagementModelsUserManagementTenantContext;
    tenantConfigs?: TenantManagementDataEntitiesTenantConfig[] | null;
    tenantId?: string;
    timezoneInfo?: string | null;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesTenantRead = {
    accounts?: TenantManagementDataEntitiesAccountRead[] | null;
    contactEmail?: string | null;
    contactName?: string | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    entityCache?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    profile?: TenantManagementModelsUserManagementTenantContext;
    tenantConfigs?: TenantManagementDataEntitiesTenantConfig[] | null;
    tenantId?: string;
    timezoneInfo?: string | null;
    users?: TenantManagementDataEntitiesUserRead[] | null;
};
export type TenantManagementDataEntitiesTenantConfig = {
    category?: string | null;
    context?: string | null;
    key?: string | null;
    tenant?: TenantManagementDataEntitiesTenant;
    tenantConfigId?: number;
    tenantId: string;
    value?: string | null;
};
