import {
    IQuickBookInitialState,
    TabOption,
} from '../../utils/Interface/QuickBookInterface';

export const QUICKBOOKS_INITIAL_STATE: IQuickBookInitialState = {
    isQuickBookAuthenticated: false,
    isTenantQbConfigCreated: true,
    selectedTabOption: TabOption.No,
    isLoading: false,
    userEmail: '',
    inputErrors: {
        userName: false,
    },
    userQbRequestID: 0,
    qbSettingId: 0,
};
