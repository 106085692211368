import { ICattlePOFeatures } from './interface';
import { getFeatureFlagValue } from '../../common/featureFlags/helpers';

export const cattlePO: ICattlePOFeatures = {
    isBP985_SettingInactiveFlag: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP985_SETTING_INACTIVE_FLAG,
    ),
    isBP1106_buypoGlobalSendInvoice: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1106_BUYPO_GLOBAL_SEND_INVOICE,
    ),
    isBP1180_estimatedValidationRemove: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1180_ESTIMATED_VALIDATION_REMOVE,
    ),
    isBP930_buypoUnitForPrice: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP930_BUYPO_UNIT_FOR_PRICE,
    ),
    isBP935_buypoExportAllVariables: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP935_BUYPO_EXPORT_ALL_VARIABLES,
    ),
    isBP1181_OptimizeTruckingCompanyView: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1181_OPTIMIZE_TRAINING_COMPANY_VIEW,
    ),
    isBP1086_ownerSettings: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1086_OWNER_SETTINGS,
    ),
    isBP1085_buyerSetting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1085_BUYER_SETTING,
    ),
    isBP1084_payeeSetting: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1084_PAYEE_SETTING,
    ),
    isBP1051_matchTableHeaders: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1051_MATCH_TABLE_HEADERS,
    ),
    isBP1208_truckOrderFilterRemove: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1208_TRUCK_ORDER_FILTER_REMOVE,
    ),
    isBP1215_commissionQuanityChange: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1215_COMMISSION_QUANTITY_CHANGE,
    ),
    isBP1214_truckingCompanyAddress: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1214_TRUCKING_COMPANY_ADDRESS,
    ),
    isBP1221_brandsAsAdditionalFee: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1221_BRANDS_AS_ADDITIONAL_FEE,
    ),
    isBP1246_buypoFilterQuerySync: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1246_BUYPO_FILTER_QUERY_SYNC,
    ),
};
