import { QuickBookConstant } from '../../utils/constants/QuickBookConstant';
import DocumentIcon from '../../Assets/DocumentIcon';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { TabOption } from '../../utils/Interface/QuickBookInterface';
import {
    setSelectedTabOption,
    setInputError,
    setIsLoading,
} from '../../Redux/Reducer/quickBookSlice';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import ConfirmationModal from '../common/ConfirmationModal';
import FullPageLoader from '../common/FullPageLoader';
import { useState } from 'react';
import { validateAccordingType } from '../../utils/validation';
import { Form, InputGroup, Row } from 'react-bootstrap';
import EyeIcon from '../../Assets/EyeIcon';
import {
    useCreateUserQuickBooksConfig,
    useDownloadQbConfigFile,
    useGetFmSettingsForQuickBook,
    useUpdateFmSettingForQbConfig,
} from '../../apps/feedlotManager/modules/settings/quickBooks/hooks/quickBooksHooks';
import { FeedlotManagerCommonEnumsQbConnectionFileType } from '../../Redux/Apis/QuickBooks/baseQuickBooksApi';
import QuickBookVerificationModal from '../../apps/feedlotManager/modules/settings/quickBooks/components/QuickBookVerificationModal';

const QuickBookIntegration = () => {
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const {
        isTenantQbConfigCreated,
        isQuickBookAuthenticated,
        isLoading,
        userEmail,
        inputErrors,
        qbSettingId,
    } = useAppSelector(state => state.quickBook);

    const {
        isOpen: isQuickBookVerificationModalOpen,
        open: openQuickBookVerificationModal,
        close: closeQuickBookVerificationModal,
    } = useModalStatus();

    const {
        isOpen: isDisconnectQBModalOpen,
        open: openDisconnectQBModal,
        close: closeDisconnectQBModal,
    } = useModalStatus();

    useGetFmSettingsForQuickBook();
    const { downloadQbConfigFile } = useDownloadQbConfigFile();
    const { updateFmSettingQbConfig } = useUpdateFmSettingForQbConfig();

    const { createUserQuickBooksConfig } = useCreateUserQuickBooksConfig(
        userEmail,
        qbSettingId,
    );

    const handleToggle = async (selectedOption: TabOption) => {
        if (selectedOption === TabOption.Yes && !isQuickBookAuthenticated) {
            if (!isTenantQbConfigCreated) {
                openQuickBookVerificationModal();
            } else {
                await updateFmSettingQbConfig(
                    qbSettingId,
                    !isQuickBookAuthenticated,
                );
            }
        } else if (
            selectedOption === TabOption.No &&
            isQuickBookAuthenticated
        ) {
            openDisconnectQBModal();
        }
        dispatch(setSelectedTabOption(selectedOption));
    };

    const handleDownload = (documentFile: {
        key: FeedlotManagerCommonEnumsQbConnectionFileType;
        extension: string;
    }) => {
        downloadQbConfigFile(documentFile.key, documentFile.extension);
    };

    const confirmDisconnectQBHandler = (isConfirmed: boolean) => {
        dispatch(setIsLoading(true));
        closeDisconnectQBModal();
        updateFmSettingQbConfig(qbSettingId, !isConfirmed);
    };

    const validateUserInput = (): boolean => {
        let isValidate = true;
        isValidate = validateAccordingType('email', userEmail);
        if (!isValidate) {
            dispatch(setInputError({ ...inputErrors, userName: true }));
            isValidate = false;
        }
        return isValidate;
    };

    const closeModalHandler = async () => {
        const isUserValidate = validateUserInput();
        if (isUserValidate) {
            closeQuickBookVerificationModal();
            await createUserQuickBooksConfig();
        }
    };

    return (
        <>
            <QuickBookVerificationModal
                showModal={isQuickBookVerificationModalOpen}
                closeModalHandler={closeModalHandler}
                closeModal={closeQuickBookVerificationModal}
            />
            <ConfirmationModal
                confirmationMessage={
                    QuickBookConstant.messages.noConfirmationMessage
                }
                showModal={isDisconnectQBModalOpen}
                confirmationModalHandler={confirmDisconnectQBHandler}
            />
            <div className="pens-container">
                <h5 className="my-3">{QuickBookConstant.header}</h5>
                <div
                    className="o-switch btn-group show-toggle my-2"
                    data-toggle="buttons"
                    role="group"
                >
                    {QuickBookConstant.tabOptions.map(option => (
                        <label
                            key={option}
                            className={`btn btn-secondary py-1 ${
                                isQuickBookAuthenticated ===
                                (option === TabOption.Yes)
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <input
                                type="radio"
                                name={
                                    QuickBookConstant.tabOptionTitle.inputName
                                }
                                onChange={() => handleToggle(option)}
                                checked={
                                    isQuickBookAuthenticated ===
                                    (option === TabOption.Yes)
                                }
                            />
                            {option === TabOption.Yes
                                ? QuickBookConstant.tabOptionTitle.yes
                                : QuickBookConstant.tabOptionTitle.no}
                        </label>
                    ))}
                </div>
                <hr />
                {isLoading && <FullPageLoader />}
                {isQuickBookAuthenticated && !isLoading && (
                    <div>
                        <Row className="mb-2">
                            <Form.Group className="col-3">
                                <Form.Label className="mb-0">
                                    <h5>{QuickBookConstant.connectPassword}</h5>
                                </Form.Label>
                                <InputGroup className="qwc-password-input-group">
                                    <Form.Control
                                        size="sm"
                                        type={`${showPassword ? 'text' : 'password'}`}
                                        name="password"
                                        defaultValue={
                                            QuickBookConstant.qwcPassword
                                        }
                                    />
                                    <InputGroup.Text
                                        className="qwc-password-eye-icon cursor-pointer"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                    >
                                        <EyeIcon />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <div>
                            <h5>{QuickBookConstant.quickBookDocument}</h5>
                            <div className="d-inline-flex flex-column justify-content-center gap-1 py-3 px-4">
                                {QuickBookConstant.documentFiles.map(
                                    (documentFile, index) => {
                                        return (
                                            <div
                                                className="d-inline-flex flex-row gap-2 align-items-center cursor-pointer"
                                                key={index}
                                                onClick={() =>
                                                    handleDownload(documentFile)
                                                }
                                            >
                                                <DocumentIcon />
                                                <span>{documentFile.name}</span>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default QuickBookIntegration;
