import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { SideNavItems } from '../../utils/constants/RoleConstants';
import { INavItemDetail } from '../../utils/Interface/NavInterface';
import Constants from '../../utils/Constants';
import { commonFeatures } from '../../apps/common/featureFlags/commonFeatures';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA13354On_RestrictLegacyUsersOnUserManagement } = commonFeatures;

const { isCA9919On_ManageUsersPage } = feedlotManagerFeatures;

const SideNavBuyPO = () => {
    const { isAuthenticated, getUsersRoles } = useAuthContext();

    const getNavItem = (navItemDetail: INavItemDetail) => {
        const navItemRoles = navItemDetail.roles;
        for (let roleIndex = 0; roleIndex < navItemRoles.length; roleIndex++) {
            if (getUsersRoles().includes(navItemRoles[roleIndex])) {
                return navItem(navItemDetail, roleIndex);
            }
        }
        return null;
    };

    const navItem = (navItemDetail: INavItemDetail, roleIndex: number) => {
        return (
            <Link key={roleIndex} to={navItemDetail.redirectUrl}>
                <li>{navItemDetail.label}</li>
            </Link>
        );
    };

    return (
        <>
            {isAuthenticated() && (
                <Accordion className="accordion">
                    {SideNavItems.buyPoSideNavItem?.subBuyPoItemsPrimary?.map(
                        (navItem, index) => {
                            let component: JSX.Element | null = null;
                            for (let i = 0; i < navItem.roles.length; i++) {
                                if (
                                    getUsersRoles().includes(navItem.roles[i])
                                ) {
                                    component = (
                                        <Accordion.Item
                                            className="accordion-item"
                                            key={index}
                                            eventKey="-1"
                                        >
                                            <Link to={navItem.redirectUrl}>
                                                <Accordion.Header className="accordion-header">
                                                    {navItem.icon}
                                                    {navItem.label}
                                                </Accordion.Header>
                                            </Link>
                                        </Accordion.Item>
                                    );
                                    break;
                                }
                            }
                            return component;
                        },
                    )}
                    {SideNavItems.buyPoSideNavItem?.subBuyPoItemsSecondary
                        ?.filter(
                            navItem =>
                                !isCA13354On_RestrictLegacyUsersOnUserManagement ||
                                !navItem.authType ||
                                navItem.authType ===
                                    localStorage.getItem(
                                        Constants.StorageItems.AUTH_TYPE,
                                    ),
                        )
                        .map((navItem, index) => {
                            let component: JSX.Element | null = null;
                            for (let i = 0; i < navItem.roles.length; i++) {
                                if (
                                    getUsersRoles().includes(navItem.roles[i])
                                ) {
                                    component = (
                                        <Accordion.Item
                                            className="accordion-item"
                                            key={index}
                                            eventKey={index.toString()}
                                        >
                                            <Accordion.Header
                                                key={index}
                                                className="accordion-header"
                                            >
                                                {navItem.icon}
                                                {navItem.label}
                                            </Accordion.Header>
                                            <Accordion.Body className="accordion-body">
                                                {navItem.subSideNavItems?.map(
                                                    (
                                                        sideNavItemDetail: INavItemDetail,
                                                        subIndex: number,
                                                    ) => {
                                                        return sideNavItemDetail.isDisabled ? null : (
                                                            <Accordion.Item
                                                                className="accordion-subitem"
                                                                key={subIndex}
                                                                eventKey={subIndex.toString()}
                                                            >
                                                                {getNavItem(
                                                                    sideNavItemDetail,
                                                                )}
                                                            </Accordion.Item>
                                                        );
                                                    },
                                                )}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                    break;
                                }
                            }
                            return component;
                        })}
                    {isCA9919On_ManageUsersPage &&
                        isCA13354On_RestrictLegacyUsersOnUserManagement &&
                        SideNavItems.buyPoSideNavItem?.subBuyPoItemsTertiary?.map(
                            (navItem, index) => {
                                let component: JSX.Element | null = null;
                                for (let i = 0; i < navItem.roles.length; i++) {
                                    if (
                                        getUsersRoles().includes(
                                            navItem.roles[i],
                                        )
                                    ) {
                                        component = (
                                            <Accordion.Item
                                                className="accordion-item"
                                                key={index}
                                                eventKey="-1"
                                            >
                                                <Link to={navItem.redirectUrl}>
                                                    <Accordion.Header className="accordion-header">
                                                        {navItem.icon}
                                                        {navItem.label}
                                                    </Accordion.Header>
                                                </Link>
                                            </Accordion.Item>
                                        );
                                        break;
                                    }
                                }
                                return component;
                            },
                        )}
                </Accordion>
            )}
        </>
    );
};

export default SideNavBuyPO;
